html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.topLevelContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Full viewport height */

}

.app {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.mainBody {
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  /* Fills the available space */
  flex-direction: column;
  padding: 15px;
  padding-bottom: 0px;
  height: 100%;
}

.mainAppContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer {
  /* position: fixed; Fixed position */
  left: 0;
  /* Align to the left edge */
  bottom: 0;
  /* Align to the bottom edge */
  width: 100%;
  /* Full width */
  text-align: center;
  /* Center the text */
  padding: 10px 0;
  /* Consistent font styling */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Base styling for the navigation bar */
nav {
  text-align: right;
  /* Align links to the right */
  padding: 10px 0;
  border-bottom: 1px solid #DDDDDD;
  /* Light border for subtle separation */
  overflow: hidden;
  position: fixed;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 100;
  /* Make sure it stays on top */

}

/* Styling for navigation links */
header a,
Link {
  color: #333333;
  /* Dark gray for minimalistic look */
  text-decoration: none;
  /* No underlines on links */
}

/* Responsive design: Stack the navigation bar on smaller screens */
@media screen and (max-width: 600px) {
  nav {
    text-align: center;
    /* Center align links on small screens */
  }

  nav a {
    display: block;
    /* Stack links vertically */
    width: 100%;
    /* Full width */
  }
}

/* Logo styling */
.nav-logo {
  height: 50px;
  /* Adjust size as needed */
}