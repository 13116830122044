table {
    width: 100%;
    /* Table takes full width of its container */
    border-collapse: collapse;
    /* Dark gray text for minimalistic look */
    margin: auto;
    max-width: 100%;
}

th,
td {
    border: 1px solid #DDDDDD;
    /* Light border for table cells */
    text-align: center;
    /* Aligns text to the left in cells */
    padding: 8px;
    /* Padding inside cells */
}

/*th {*/
/*    background-color: #E8F0FE;*/
/*    !* Light blue background for header *!*/
/*    color: #333333;*/
/*    !* Keeping text color consistent *!*/
/*}*/

@media screen and (max-width: 600px) {

    td,
    th {
        display: block;
        /* Stack cells vertically on small screens */
    }

    th {
        position: sticky;
        top: 0;
        /* Keep headers at the top when scrolling */
        z-index: 1;
        /* Ensures headers stay on top */
    }
}

input {
    width: 250px;
    margin: 0 10px;
    padding: 8px;
    border: 1px solid #DDD;
    border-radius: 4px;
}

input[type="checkbox"] {
    cursor: pointer;
}

.table-input[type="text"] {
    width: 90%;
    padding: 6px;
    border-radius: 4px;
    border: 1px
}

.saveButton {
    padding: 10px 20px;
    /* Padding inside button */
    /*background-color: #E8F0FE;*/
    /*!* Light blue background for button *!*/
    /*color: #333333;*/
    /* Dark gray text */
    border: 1px solid #DDDDDD;
    /* Light border */
    font-family: 'Arial', sans-serif;
    /* Consistent font with the nav bar */
    cursor: pointer;
    /* Cursor changes to pointer on hover */
    border-radius: 4px;
    /* Slight rounding of corners */
    width: 60px;
    /* Fixed width */
    height: 40px;
    /* Fixed height */
    margin-top: 10px;
}

/*.saveButton:hover {*/
/*    background-color: #F2F2F2;*/
/*    !* Light gray background on hover *!*/
/*}*/

.top-component,
.bottom-component {
    height: 60px;
    /* Fixed height for top and bottom components */
    /* Additional styling */
}

.body-component-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
}

.header-with-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.table-parent-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 30px;
    margin-top: 10px;
    height: 100%;
    gap: 1rem;
}

.report-selector {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.table-container {
    text-align: right;
    /* Aligns the button to the right */
    margin-top: 10px;
    /* Margin on the top and bottom */
    flex-grow: 1;
    min-width: 40%;
    overflow-y: auto;
    flex-direction: column;
}

.commit-addition {
    color: green;
}

.commit-removal {
    color: red;
}

.search-container {
    text-align: right;
    /* Align the search box to the right */
    height: 40px;
    margin-bottom: 10px;
}

.search-input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    width: 200px;
    max-width: 100%;
    margin: 5px 0px;
}

.search-button {
    padding: 10px 20px;
    margin-left: 10px;
    /* Space between the input and the button */
    font-size: 14px;
    /*background-color: #FFFFFF;*/
    /*color: #333333;*/
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    cursor: pointer;
}

.search-button:hover {
    /*background-color: #F2F2F2;*/
    /* Light gray background on hover */
}

.fixed-width-medium {
    width: 135px;
    min-width: 135px;
}

.fixed-width-large {
    width: 400px;
}

.fixed-width-small {
    width: 75px;
}

.fixed-width-tiny {
    width: 25px;
}

.graph-title {
    text-align: left;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 2em;
    font-size: 16px;
    font-weight: bold;
    /* Apply bold font weight */
    /*font-family: 'Arial', sans-serif;*/
    /*!* Consistent font with the nav bar *!*/
    /*color: #333333;*/
    /* Keeping text color consistent */

}

/* Style for the dropdown container */
.dropdown {
    display: inline-block;
    position: relative;
}

/* Style for the dropdown button */
.dropbtn {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    /* light grey border */
    background-color: #fff;
    /* white background */
    cursor: pointer;
    border-radius: 4px;
    color: #333;
    /* dark grey text */
}

/* Style for the dropdown content */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    /* white background */
    min-width: 120px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #ddd;
    /* light grey border */
}

/* Style for items in the dropdown */
.dropdown-content div {
    padding: 8px 12px;
    cursor: pointer;
    color: #555;
    /* medium grey text */
}

/* Style for item hover */
.dropdown-content div:hover {
    background-color: #f7f7f7;
    /* very light grey for hover */
}

/* Display dropdown content on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.email-input-container {
    max-width: 800px;
    /* Maximum width, you can adjust as needed */
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

/* Custom Toast Styling to Match Minimalistic Theme */
.Toastify__toast--success {
    /*background-color: #DDDDDD;*/
    /*!* Light gray background *!*/
    /*color: #333333;*/
    /* Dark gray text for contrast */
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
}

.Toastify__toast-body {
    margin: auto;
    /* Center the text inside the toast */
}

.Toastify__progress-bar--success {
    background: #AAAAAA;
    /* Slightly darker gray for progress bar */
}

.apikey-modal {
    width: 400px;
    height: 300px;
    display: flex;
    /* Use flexbox for modal content layout */
    flex-direction: column;
    /* Stack children vertically */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.custom-modal {
    width: 400px;
    height: 500px;
    display: flex;
    /* Use flexbox for modal content layout */
    flex-direction: column;
    /* Stack children vertically */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.email-textarea-container {
    flex-grow: 1;
    /* Allow the container to grow and fill available space */
    overflow-y: auto;
    /* Allow container to scroll when content overflows */
    padding: 10px;
}

.email-textarea {
    height: 100%;
    width: 100%;
    overflow: auto;
    /* Allow scrolling when content overflows vertically */
    overflow-x: scroll;
    /* Prevent horizontal scrolling */
    resize: none;
    font-size: 14px;
    border: 0;
    padding: 0;
    outline: none;
    /* Remove the outline on focus */
}

.modal-save {
    margin-bottom: 10px;
    margin-right: 10px;
}


/* Styles for the overlay */
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Semi-transparent black overlay */
}

.modal-grow-section {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
}

.padded {
    margin: 5px;
}

.fill-width {
    width: 85%
}

/* Default tooltip styles */
.recharts-tooltip {
    /*background-color: black;*/
    /*color: white;*/
}

.brand_image {
    height: 4em;
    padding-left: 1em;
    padding-right: 1em;
}
.brand_image {
    animation: bounce 1s alternate;
}

.medium_brand_image {
    height: 2.5em;
    padding-left: 2em;
    padding-right: 1em;
    animation: bounce 1s alternate;
}

@keyframes bounce {
    0% {
        transform: translateY(10px); /* Start from a downward position */
    }
    50% {
        transform: translateY(-10px); /* Move up */
    }
    100% {
        transform: translateY(0); /* Return to original position */
    }
}



@media only screen and (max-width: 768px) {
    .brand_image {
        height: 2em;
    }

    .medium_brand_image {
        height: 1em;
        padding-left: 1em;
        padding-right: 1em;
    }
}

.spaced-container {
    display: flex;
    gap: 1rem
}

.integration-column {
    display: flex;
    align-items: center;
}