.email-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px; /* Adjust the gap as needed */
    margin-top: 10px;
}

.email-item {
    /* Style for each email item */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}